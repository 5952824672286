import { CTA } from "@/components";
import { BannerSlot, Link } from "@jog/react-components";
import { responsiveImages } from "@jog/shared";
import { asLink } from "@prismicio/helpers";
import classNames from "classnames";
const { getSizes } = responsiveImages();
const firstAndSecondBannerSizes = ["575", "768", "480", "680", "50vw"];
const genderBannerSizes = ["280", "370", "480", "330", "25vw"];
export const TwoSlotHomepageBanner = ({ slice: { items, primary }, className, }) => {
    var _a, _b, _c, _d, _e, _f;
    return (<div className={className}>
        <div className={classNames("grid grid-cols-2 gap-0.5")}>
            <div>
                <Link to={asLink(primary.left_banner_url)}>
                    <BannerSlot buttonText={primary.left_button_text} buttonTopPosition={primary.left_button_top_position || 0} buttonBgColor={primary.left_button_bg_color || ""} buttonTextColor={primary.left_button_text_color || ""} colorHover={primary.left_button_hover_text_color || ""} bgColorHover={primary.left_button_hover_bg_color || ""} borderWidth={primary.left_button_border_width || 0} borderColor={primary.left_button_border_color || ""} fontSizeMobile={primary.left_button_font_size_mobile || ""} sizes={getSizes(firstAndSecondBannerSizes)} src={((_a = primary.left_banner_image) === null || _a === void 0 ? void 0 : _a.url) || ""} height={((_b = primary.left_banner_image.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 0} width={((_c = primary.left_banner_image.dimensions) === null || _c === void 0 ? void 0 : _c.width) || 0} CTA={CTA}/>
                </Link>
            </div>
            <div>
                <Link to={asLink(primary.right_banner_url)}>
                    <BannerSlot buttonText={primary.right_button_text} buttonTopPosition={primary.right_button_top_position || 0} buttonBgColor={primary.right_button_bg_color || ""} buttonTextColor={primary.right_button_text_color || ""} colorHover={primary.right_button_hover_text_color || ""} bgColorHover={primary.right_button_hover_bg_color || ""} borderWidth={primary.right_button_border_width || 0} borderColor={primary.right_button_border_color || ""} fontSizeMobile={primary.right_button_font_size_mobile || ""} src={((_d = primary.right_banner_image) === null || _d === void 0 ? void 0 : _d.url) || ""} height={((_e = primary.right_banner_image.dimensions) === null || _e === void 0 ? void 0 : _e.height) || 0} width={((_f = primary.right_banner_image.dimensions) === null || _f === void 0 ? void 0 : _f.width) || 0} sizes={getSizes(firstAndSecondBannerSizes)} CTA={CTA}/>
                </Link>
            </div>
        </div>
        {!!items.length && (<div className={classNames("gap-0.5 pt-0.5", items.length === 1 && "flex items-center justify-center", items.length > 1 &&
                items.length < 4 &&
                "grid grid-cols-2 lg:!flex lg:items-center lg:justify-center", items.length >= 4 && "grid grid-cols-2 lg:grid-cols-4")}>
                {items.map((item, index) => {
                var _a, _b;
                return (<div className={classNames(items.length < 4 && "w-auto lg:w-1/4")} key={index}>
                        <Link to={asLink(item.button_url)}>
                            <BannerSlot buttonText={item.button_label} buttonTopPosition={primary.gender_buttons_top_position || 0} buttonBgColor={primary.gender_buttons_bg_color || ""} buttonTextColor={primary.gender_buttons_text_color || ""} colorHover={primary.gender_button_hover_text_color || ""} bgColorHover={primary.gender_button_hover_bg_color || ""} borderWidth={primary.gender_button_border_width || 0} borderColor={primary.gender_button_border_color || ""} fontSizeMobile={primary.gender_button_font_size_mobile || ""} src={item.image.url || ""} height={((_a = item.image.dimensions) === null || _a === void 0 ? void 0 : _a.height) || 0} width={((_b = item.image.dimensions) === null || _b === void 0 ? void 0 : _b.width) || 0} sizes={getSizes(genderBannerSizes)} CTA={CTA}/>
                        </Link>
                    </div>);
            })}
            </div>)}
    </div>);
};
